import React, { Fragment } from "react"
import { Link } from "gatsby"

import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

import { classNames } from '../../utils/tailwind'


// lists a bunch of topics
const TopicFilters = ({
  topic,
  features,
  platforms, featureFilters, setFeatureFilters, 
  tags, tagFilter, setTagFilter, 
  sizes, sizeFilters, setSizeFilters,
  sort, setSort, sortOptions
}) => {

  const sizeLabels = {
    'Tiny': '<20',
    'Small': '20 - 100',
    'Medium': '100 - 1,000',
    'Large': '1,000 - 10,000',
    'Huge': '10,000 - 100,000',
    'Massive': '100,000 - 1,000,000',
    'Gigantic': '1,000,000+',
  }


  return (
    <div className="flex space-x-1 sm:space-x-4 flex-wrap">
      {/*{platforms && platforms.length ? (
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                className={classNames(
                  open ? 'text-gray-900' : 'text-gray-500',
                  'group flex mt-1 block p-2 sm:px-3 sm:py-2 w-full text-xs border-gray-300 focus:outline-none focus:ring-rose-500 border border-gray-200 focus:border-rose-500 sm:text-sm rounded-md'
                )}
              >
                <span>Platforms
                  <span className="hidden sm:inline-block opacity-50 ml-1 text-xs">({platforms.filter(p => p.count).length})</span>
                </span>
                <ChevronDownIcon
                  className={classNames(open ? 'text-gray-600' : 'text-gray-400', 'hidden sm:block ml-auto h-5 w-5 group-hover:text-gray-500')}
                  aria-hidden="true"
                />
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 left-0 mt-3 px-2 w-screen max-w-xs sm:px-0">
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="relative grid gap-1 bg-white p-2">

                      {platforms.filter(p => p.count > 0).map((p) => (
                        <Link
                          key={p.slug}
                          to={`/topics/${topic.slug}/platform/${p.slug}/`}
                          disabled={p.count === 0}
                          className="p-1 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                        >
                          <div className="ml-0">
                            <div className="text-base font-medium text-gray-900 flex">
                              <div className="opacity-50 bold mr-1 w-8 text-center">{p.count}</div>
                              <div>{p.name}</div>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      ) : ''}*/}
      

      {features && features.length ? (
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                className={classNames(
                  open ? 'text-gray-900' : 'text-gray-500',
                  featureFilters.length ? 'border-rose-500 bg-rose-50' : '',
                  'group flex mt-1 block p-2 sm:px-3 sm:py-2 w-full text-xs border-gray-300 focus:outline-none focus:ring-rose-500 border border-gray-200 focus:border-rose-500 sm:text-sm rounded-md'
                )}
              >
                <span>Features
                  <span className="hidden sm:inline-block opacity-50 ml-1 text-xs">
                    (
                      {featureFilters.length ? (
                        <span>{featureFilters.length} / </span>
                      ) : ''}
                      <span>{features.filter(f => f.count).length}</span>
                    )
                  </span>
                </span>
                <ChevronDownIcon
                  className={classNames(open ? 'text-gray-600' : 'text-gray-400', 'hidden sm:block ml-auto h-5 w-5 group-hover:text-gray-500')}
                  aria-hidden="true"
                />
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 right-0 mt-3 px-2 w-screen max-w-xs sm:px-0">
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="relative grid gap-1 bg-white p-2">
                      {featureFilters.length ? (
                        <div
                          onClick={() => setFeatureFilters([])}
                          className="p-1 flex items-start cursor-pointer rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                        >
                          {/*<item.icon className="flex-shrink-0 h-6 w-6 text-rose-600" aria-hidden="true" />*/}
                          <div className="ml-0">
                            <div className="text-base font-medium text-gray-900 flex">
                              <div className="opacity-50 bold mr-1 w-8 text-center">--</div>
                              <div>Clear Filter</div>
                            </div>
                          </div>
                        </div>
                      ) : ''}

                      {features.filter(f => f.count).map((f) => (
                        <div
                          key={f.name}
                          disabled={f.count === 0}
                          onClick={() => {
                            if (!featureFilters.map(f => f.name).includes(f.name)){
                              setFeatureFilters([...featureFilters, f])
                            } else {
                              setFeatureFilters(featureFilters.filter(s => s.name !== f.name))
                            }
                          }}
                          disabled={f.count === 0}
                          className={`p-1 flex items-start cursor-pointer rounded-lg hover:bg-gray-50 transition ease-in-out duration-150 ${featureFilters.map(s => s.name).includes(f.name) ? 'bg-rose-50' : ''}`}
                        >
                          {/*<item.icon className="flex-shrink-0 h-6 w-6 text-rose-600" aria-hidden="true" />*/}
                          <div className="ml-0">
                            <div className="text-base font-medium text-gray-900 flex">
                              <div className="opacity-50 bold mr-1 w-8 text-center">{f.count}</div>
                              <div>{f.name}
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      {/*{features.filter(f => f.count > 0).map((f) => (
                        <Link
                          key={f.slug}
                          to={`/topics/${topic.slug}/feature/${f.slug}/`}
                          disabled={f.count === 0}
                          className="p-1 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                        >
                         
                          <div className="ml-0">
                            <div className="text-base font-medium text-gray-900 flex">
                              <div className="opacity-50 bold mr-1 w-8 text-center">{f.count}</div>
                              <div>{f.name}</div>
                            </div>
                          </div>
                        </Link>
                      ))}*/}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      ) : ''}

      {sizes && sizes.length ? (
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                className={classNames(
                  open ? 'text-gray-900' : 'text-gray-500',
                  sizeFilters.length ? 'border-rose-500 bg-rose-50' : '',
                  'group flex mt-1 block p-2 sm:px-3 sm:py-2 w-full text-xs border-gray-300 focus:outline-none focus:ring-rose-500 border border-gray-200 focus:border-rose-500 sm:text-sm rounded-md'
                )}
              >
                <span>Sizes
                  <span className="hidden sm:inline-block opacity-50 ml-1 text-xs">
                    (
                      {sizeFilters.length ? (
                        <span>{sizeFilters.length} / </span>
                      ) : ''}
                      <span>{sizes.filter(f => f.count).length}</span>
                    )
                  </span>
                </span>
                <ChevronDownIcon
                  className={classNames(open ? 'text-gray-600' : 'text-gray-400', 'hidden sm:block ml-auto h-5 w-5 group-hover:text-gray-500')}
                  aria-hidden="true"
                />
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 right-0 mt-3 px-2 w-screen max-w-xs sm:px-0">
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="relative grid gap-1 bg-white p-2">
                      {sizeFilters.length ? (
                        <div
                          onClick={() => setSizeFilters([])}
                          className="p-1 flex items-start cursor-pointer rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                        >
                          {/*<item.icon className="flex-shrink-0 h-6 w-6 text-rose-600" aria-hidden="true" />*/}
                          <div className="ml-0">
                            <div className="text-base font-medium text-gray-900 flex">
                              <div className="opacity-50 bold mr-1 w-8 text-center">--</div>
                              <div>Clear Filter</div>
                            </div>
                          </div>
                        </div>
                      ) : ''}

                      {sizes.map((s) => (
                        <div
                          key={s.name}
                          onClick={() => {
                            if (!sizeFilters.map(f => f.name).includes(s.name)){
                              setSizeFilters([...sizeFilters, s])
                            } else {
                              setSizeFilters(sizeFilters.filter(f => s.name !== f.name))
                            }
                          }}
                          disabled={s.count === 0}
                          className={`p-1 flex items-start cursor-pointer rounded-lg hover:bg-gray-50 transition ease-in-out duration-150 ${sizeFilters.map(s => s.name).includes(s.name) ? 'bg-rose-50' : ''}`}
                        >
                          {/*<item.icon className="flex-shrink-0 h-6 w-6 text-rose-600" aria-hidden="true" />*/}
                          <div className="ml-0">
                            <div className="text-base font-medium text-gray-900 flex">
                              <div className="opacity-50 bold mr-1 w-8 text-center">{s.count}</div>
                              <div>{s.name}
                                {sizeLabels[s.name] ? (
                                  <span className="opacity-50 text-xs"> ({sizeLabels[s.name]})</span>
                                ) : ''}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      
                      

                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      ) : ''}

      {tags && tags.length ? (
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                className={classNames(
                  open ? 'text-gray-900' : 'text-gray-500',
                  tagFilter ? 'border-rose-500 bg-rose-50' : '',
                  'group flex mt-1 block p-2 sm:px-3 sm:py-2 w-full text-xs border-gray-300 focus:outline-none focus:ring-rose-500 border border-gray-200 focus:border-rose-500 sm:text-sm rounded-md'
                )}
              >
                <span>Tags
                  <span className="hidden sm:inline-block opacity-50 ml-1 text-xs">
                    (
                      {tagFilter ? (
                        <span>1 / </span>
                      ) : ''}
                      {tags.filter(f => f.count).length}
                    )
                  </span>
                </span>
                <ChevronDownIcon
                  className={classNames(open ? 'text-gray-600' : 'text-gray-400', 'hidden sm:block ml-auto h-5 w-5 group-hover:text-gray-500')}
                  aria-hidden="true"
                />
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 right-0 mt-3 px-2 w-screen max-w-xs sm:px-0">
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="relative grid gap-1 bg-white p-2">
                      

                      {tags.map((t) => (
                        <div
                          key={t.name}
                          onClick={() => setTagFilter(t)}
                          // to={`/topics/${topic.slug}/feature/${f.slug}/`}
                          disabled={t.count === 0}
                          className={`p-1 flex items-start cursor-pointer rounded-lg hover:bg-gray-50 transition ease-in-out duration-150 ${t.name === (tagFilter && tagFilter.name) ? 'bg-rose-50' : ''}`}
                        >
                          {/*<item.icon className="flex-shrink-0 h-6 w-6 text-rose-600" aria-hidden="true" />*/}
                          <div className="ml-0">
                            <div className="text-base font-medium text-gray-900 flex">
                              <div className="opacity-50 bold mr-1 w-8 text-center">{t.count}</div>
                              <div>{t.name}</div>
                            </div>
                          </div>
                        </div>
                      ))}

                      {tagFilter ? (
                        <div
                          onClick={() => setTagFilter(null)}
                          className="p-1 flex items-start cursor-pointer rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                        >
                          {/*<item.icon className="flex-shrink-0 h-6 w-6 text-rose-600" aria-hidden="true" />*/}
                          <div className="ml-0">
                            <div className="text-base font-medium text-gray-900 flex">
                              <div className="opacity-50 bold mr-1 w-8 text-center">--</div>
                              <div>Clear Filter</div>
                            </div>
                          </div>
                        </div>
                      ) : ''}
                      

                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      ) : ''}

      {sortOptions && sortOptions.length ? (
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                className={classNames(
                  open ? 'text-gray-900' : 'text-gray-500',
                  // sort ? 'border-rose-500 bg-rose-50' : '',
                  'group flex mt-1 block p-2 sm:px-3 sm:py-2 w-full text-xs border-gray-300 focus:outline-none focus:ring-rose-500 border border-gray-200 focus:border-rose-500 sm:text-sm rounded-md'
                )}
              >
                <span>{sort}</span>
                <ChevronDownIcon
                  className={classNames(open ? 'text-gray-600' : 'text-gray-400', 'hidden sm:block ml-auto h-5 w-5 group-hover:text-gray-500')}
                  aria-hidden="true"
                />
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 right-0 mt-3 px-2 w-36 sm:px-0">
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="relative grid gap-1 bg-white p-2">
                      {sortOptions.map((t) => (
                        <div
                          key={t}
                          onClick={() => setSort(t)}
                          className={`p-1 flex items-start cursor-pointer rounded-lg hover:bg-gray-50 transition ease-in-out duration-150 ${t === sort ? 'bg-rose-50' : ''}`}
                        >
                          {/*<item.icon className="flex-shrink-0 h-6 w-6 text-rose-600" aria-hidden="true" />*/}
                          <div className="ml-0">
                            <div className="text-base font-medium text-gray-900 flex">
                              <div>{t}</div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      ) : ''}
    </div>
  )
}

export default TopicFilters;

