import React, { useState } from "react"
import { Link, graphql } from "gatsby"

import { PlusSmIcon } from '@heroicons/react/solid'

import SEO from "../components/common/seo"
import Layout from "../components/common/layout"
import TopicCard from "../components/topic/topiccard"
import TopicFilters from "../components/topic/filters"
import Image from "../components/common/image"
import CommunitiesTable from "../components/community/communitiestable"
import FeatureIcon from "../components/feature/featureicon"

import { unslugify } from '../utils/text'
import { sortCommunities } from '../utils/communities'

const FeaturePage = ({ data, classes, pageContext }) => {
  const [topicsToShow, setTopicsToShow] = useState(8);  // default topics to show, until user expands

  const feature = data.feature;
  const communities = data.communities.nodes.filter(c => c[feature.slug]).sort((a,b) => (a.members < b.members) ? 1 : ((b.members < a.members) ? -1 : 0));
  const count = communities.length;

  const [sizeFilters, setSizeFilters] = useState([]);
  const sortOptions = ["Popular", "Ratings", "Largest", "Newest"];
  const [sort, setSort] = useState(sortOptions[0]);

  var description = `Discover${count ? ' ' + count + ' ' : ' '} communities ${feature.sentenceEnding}`;
  if (communities.length > 0){
    description += ', like '
    description += communities.slice(0, 3).map(c => c.name).join(', ')
    description += ' and more.'
  } else {
    description += '.'
  }

  // make the SEO title
  var title = '';
  // if (count > 1){
  //   title += count
  // }
  // if (title !== ''){title += ' '}
  if (feature.prefix){
    title += `${unslugify(feature.prefix)} communit${count === 1 ? 'y' : 'ies'}`
  } else {
    title += `Communit${count === 1 ? 'y' : 'ies'} ${feature.sentenceEnding}`
  }

  // get topics that this feature is amongst
  var topicCounts = {};  // key is slug, value is count
  communities.map(c => c.topics).forEach((slugs) => {
    const topics = slugs ? slugs.split(',') : [];
    topics.forEach(t => {
      if (topicCounts[t]){
        topicCounts[t]['count'] += 1;
      } else if (data.topics.nodes.filter(topic => topic.slug === t)[0]) {
        topicCounts[t] = {'topic': data.topics.nodes.filter(topic => topic.slug === t)[0] , 'count': 1};
      }
    }) 
  });

  // sizes of communities
  var sizes = ["Tiny", "Small", "Medium", "Large", "Huge", "Massive", "Gigantic", "Unknown"].map(s => {
    return {
      'name': s,
      'count': communities.filter(c => c.memberSize === s).length
    }
  });

  // filter communiites based on filter
  var filteredCommunities = communities;
  if (sizeFilters.length){
    filteredCommunities = filteredCommunities.filter(c => sizeFilters.map(f => f.name).includes(c.memberSize))
  }

  // sort them
  filteredCommunities = sortCommunities(sort, filteredCommunities)

  return (
    <Layout pageTitle={title} enableShare={true}>
      <SEO title={count ? `${count} ${title}` : title} description={description} imageUrl={feature.iconActive}
        generateImage={true} path={`/features/${feature.slug}/`}
      />

      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex items-center justify-between">
          <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">
            About Communities Tagged with "{unslugify(feature.slug)}"
          </h2>
        </div>
      </div>

      <div className="mt-2 flex">
        <div className="mr-2">
          <span
            className={`bg-yellow-100 text-yellow-500 rounded-lg inline-flex p-4 ring-4 ring-white`}
          >
            <FeatureIcon feature={feature.slug} className="h-6 w-6"/>
          </span>
        </div>
        {feature.description ? (
          <p className="mt-1 text-gray-500">
            {feature.description}
          </p>
        ) : ''}
      </div>


      {Object.keys(topicCounts).length > 1 ? (
        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-200" />
            </div>
            <div className="relative flex items-center justify-between">
              <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">
                Topics with this community feature
              </h2>
            </div>
          </div>

          <div className="mt-1 text-gray-500">
            <ul className={`mt-2 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8`}>
              {Object
                .keys(topicCounts)
                .map(key => topicCounts[key])
                .sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0))
                .map((t, i) =>
              (
                <TopicCard key={t.topic.slug} feature={feature} topic={t.topic} countOverride={t.count} mini={true} hidden={i > topicsToShow - 1}/>
              ))}
            </ul>
          
            {topicsToShow < Object.keys(topicCounts).length ? (
              <div className="p-4 mx-auto text-center">
                <div className="mx-auto text-gray-600 font-semibold text-xs opacity-75 hover:opacity-100 cursor-pointer"
                  onClick={() => setTopicsToShow(Object.keys(topicCounts).length)}
                >
                  Show All {Object.keys(topicCounts).length} →
                </div>
              </div>
            ) : ''}
          </div>
        </div>
      ) : ''}

      <div className="relative mt-6">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex items-center justify-between">
          <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">
            Online {count === 1 ? 'community' : 'communities'} {feature.sentenceEnding}
          </h2>
          <span className="font-semibold text-xs text-gray-500 bg-white px-1 pr-2">{count}</span>
          <Link to="/submit/" className="ml-auto">
            <button
              type="button"
              className="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
            >
              <PlusSmIcon className="-ml-1.5 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
              <span>New</span>
            </button>
          </Link>
        </div>
      </div>

      {count > 10 ? (
        <TopicFilters
          // topic={topic} platforms={platforms} features={features}
          // tags={tags} tagFilter={tagFilter} setTagFilter={setTagFilter}
          sizes={sizes} sizeFilters={sizeFilters} setSizeFilters={setSizeFilters}
          sort={sort} setSort={setSort} sortOptions={sortOptions}
        />
      ) : ''}

      {count > 0 ? (
        <div className="mt-1">
          <CommunitiesTable communities={filteredCommunities} showTopics={true} linkModal={true} showRankings={true} />
        </div>
      ) : (
        <div className="mt-1">
          <div style={{padding: '20px 0 0', textAlign: 'center', fontWeight: '500', color: '#95a5a6'}}>
            There are no online communities with this feature yet <br/><br/>
          Do you know one? Please <Link to="/submit/">submit it</Link>!
          </div>
        </div>
      )}
    </Layout>
  )
}

export const featurePageQuery = graphql`
  query FeaturePage($slug: String) {
    site {
      siteMetadata {
        title
      }
    }
    feature: googleSpreadsheetSourceFeatures(slug: {eq: $slug}) {
      slug
      name
      order
      sentenceEnding
      count
      icon
      iconActive
      description
      prefix
    }
    communities: allGoogleSpreadsheetSourceCommunities(filter: {removed: {ne: true}}, sort: {fields: members, order: DESC}) {
      totalCount
      nodes {
        name
        slug
        members
        memberSize
        countFeatures
        order
        platform
        description
        topics
        logo
        forum
        chat
        apply
        pairing
        events
        perks
        jobs
        newsletter
        paid
        courses
        tools
        token
        reviewCount
        reviewAvg
        reviewsPositive
        gold
        sponsored
        visitors
        views
        redirects
      }
    }
    topics: allGoogleSpreadsheetSourceTopics(filter: {count: {gt: 0}}) {
      nodes {
        slug
        name
        count
        image
        people
      }
    }
  }
`

export default FeaturePage

